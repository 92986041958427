"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import createTooltip from "src/decidim/tooltips";
export default function(node) {
  return __async(this, null, function* () {
    const container = node.firstElementChild;
    if (container) {
      const response = yield fetch(node.dataset.tooltipUrl, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (response.ok) {
        const json = yield response.json();
        container.dataset.tooltip = json.data;
        createTooltip(container);
      } else {
        console.error(response.status, response.statusText);
      }
    }
  });
}
