"use strict";
const getAbsolutePosition = (node, relativeParent) => {
  const { top, left, width, height } = node.getBoundingClientRect();
  let [pageX, pageY] = [window.pageXOffset, window.pageYOffset];
  if (relativeParent) {
    const { topLeft: [parentX, parentY] } = getAbsolutePosition(relativeParent);
    [pageX, pageY] = [pageX - parentX, pageY - parentY];
  }
  return {
    topLeft: [pageX + left, pageY + top],
    topCenter: [pageX + left + width / 2, pageY + top],
    topRight: [pageX + left + width, pageY + top],
    middleLeft: [pageX + left, pageY + top + height / 2],
    middleCenter: [pageX + left + width / 2, pageY + top + height / 2],
    middleRight: [pageX + left + width, pageY + top + height / 2],
    bottomLeft: [pageX + left, pageY + top + height],
    bottomCenter: [pageX + left + width / 2, pageY + top + height],
    bottomRight: [pageX + left + width, pageY + top + height]
  };
};
export default function(node) {
  const { tooltip: tooltipHtml } = node.dataset;
  const div = document.createElement("div");
  div.innerHTML = tooltipHtml;
  const tooltip = div.firstElementChild;
  if (!(tooltip instanceof HTMLElement)) {
    return;
  }
  node.removeAttribute("title");
  tooltip.id = tooltip.id || `tooltip-${Math.random().toString(36).substring(7)}`;
  tooltip.setAttribute("aria-hidden", true);
  const useMobile = /Mobi|Android/i.test(navigator.userAgent);
  let removeTooltip = () => {
    tooltip.setAttribute("aria-hidden", "true");
  };
  const OutsideClick = (event) => {
    if (!tooltip.contains(event.target) && event.target !== node) {
      removeTooltip();
    }
  };
  removeTooltip = () => {
    tooltip.setAttribute("aria-hidden", "true");
    document.removeEventListener("click", OutsideClick);
  };
  const toggleTooltip = (event) => {
    event.preventDefault();
    if (tooltip.getAttribute("aria-hidden") === "false") {
      tooltip.setAttribute("aria-hidden", "true");
      return;
    }
    Array.from(document.body.children).map((child) => child.id.startsWith("tooltip") && child.remove());
    document.body.appendChild(tooltip);
    node.setAttribute("aria-describedby", tooltip.id);
    const { topCenter, bottomCenter, middleRight, middleLeft } = getAbsolutePosition(node);
    let positionX = null;
    let positionY = null;
    if (tooltip.classList.contains("bottom")) {
      [positionX, positionY] = bottomCenter;
    } else if (tooltip.classList.contains("left")) {
      [positionX, positionY] = middleLeft;
    } else if (tooltip.classList.contains("right")) {
      [positionX, positionY] = middleRight;
    } else if (tooltip.classList.contains("top")) {
      [positionX, positionY] = topCenter;
    }
    if ((tooltip.classList.contains("top") || tooltip.classList.contains("bottom")) && positionX < Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) * 0.5) {
      tooltip.style.setProperty("--arrow-offset", "80%");
    } else {
      tooltip.style.removeProperty("--arrow-offset");
    }
    tooltip.style.top = `${positionY}px`;
    tooltip.style.left = `${positionX}px`;
    tooltip.setAttribute("aria-hidden", false);
    setTimeout(() => document.addEventListener("click", OutsideClick));
  };
  if (useMobile) {
    node.addEventListener("click", toggleTooltip);
    window.addEventListener("keydown", (event) => event.key === "Escape" && removeTooltip());
  } else {
    node.addEventListener("mouseenter", toggleTooltip);
    node.addEventListener("mouseleave", removeTooltip);
    node.addEventListener("focus", toggleTooltip);
    node.addEventListener("blur", removeTooltip);
    tooltip.addEventListener("mouseenter", () => tooltip.setAttribute("aria-hidden", false));
    tooltip.addEventListener("mouseleave", removeTooltip);
  }
}
